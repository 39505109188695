import {
  LegacyCard,
  EmptyState,
  Text,
  IndexTable,
  Link,
  Select,
  useIndexResourceState,
  FilterInterface,
  ChoiceList,
  TextField,
  TabProps,
  IndexFilters,
  useSetIndexFiltersMode,
} from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Order, QuotationMetadata } from '../../../types';
import { renderOrderStatusBadge } from '../../../utils/Common';

export function QuoteList({ setActive, setSaveError, setCounterError, user }: any) {
  const navigate = useNavigate();

  const [queryValue, setQueryValue] = useState('');
  // const [taggedWith, setTaggedWith] = useState(null);
  const [sortValue, setSortValue] = useState('DATE_CREATED_DESC');
  const [items, setItems] = useState([]);
  const [frontItems, setFrontItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const [productChoices, setProductChoices] = useState<{ label: string; value: string }[] | []>([]);
  const [productsSelected, setProductsSelected] = useState<string[] | undefined>(undefined);

  const [deselectedMetadataChoices, setDeselectedMetadataChoices] = useState<{ label: string; value: string }[] | []>([]);
  // const [metadata, setMetadata] = useState<QuotationMetadata[] | []>([]);
  const [metadataChoices, setMetadataChoices] = useState<{ label: string; value: string }[] | []>([]);
  const [metadataSelected, setMetadataSelected] = useState<string[] | undefined>(undefined);
  const [fieldValue, setFieldValue] = useState<string | undefined>(undefined);

  const resourceName = {
    singular: 'preventivo',
    plural: 'preventivi',
  };

  const handleProductsSelectedChange = useCallback((value: string[]) => setProductsSelected(value), []);
  const handleProductsSelectedRemove = useCallback(() => setProductsSelected(undefined), []);

  const handleMetadataSelectedChange = useCallback((value: string[]) => setMetadataSelected(value), []);
  const handleMetadataSelectedRemove = useCallback(() => setMetadataSelected(undefined), []);

  const handleFieldValueChange = useCallback((value: string) => setFieldValue(value), []);
  const handleFieldValueRemove = useCallback(() => setFieldValue(undefined), []);

  // View values for quotes
  const [viewValue, setViewValue] = useState<'all' | 'quote' | 'to_be_quoted' | 'to_be_completed' | 'blocked' | 'quote_rejected'>('all');

  /**
   * Data fetching
   */
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/quotes?view=${viewValue}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setItems(data.data);
          setFrontItems(data.data);

          // Set product choices in a map structure
          const tmpProductChoices: { label: string; value: string }[] = [];
          const tmpMetadataChoices: { label: string; value: string }[] = [];
          for (const item of data.data) {
            // Set product choices
            for (const lineItem of item.line_items) {
              if (!tmpProductChoices.some((choice) => choice.label === lineItem.name)) {
                tmpProductChoices.push({ label: lineItem.name, value: lineItem._id });
              }
            }

            // Set metadata choices
            if (item.metadata[0] && item.metadata[0].fields.length > 0) {
              for (const metadata of item.metadata[0].fields as QuotationMetadata[]) {
                if (!tmpMetadataChoices.some((choice) => choice.label === metadata.label)) {
                  tmpMetadataChoices.push({ label: metadata.label, value: metadata.label });
                }
              }
            }
          }
          setProductChoices(tmpProductChoices);
          setMetadataChoices(tmpMetadataChoices);
          setDeselectedMetadataChoices(tmpMetadataChoices);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrders();
  }, [update, user.id, viewValue]);

  /**
   * Handle search query
   */
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleProductsSelectedRemove();
    handleMetadataSelectedRemove();
    handleFieldValueRemove();
  }, [handleQueryValueRemove]);

  /**
   * Tabs
   */
  const tabs: TabProps[] = [
    {
      content: 'Tutti',
      id: 'Tutti',
      onAction: () => setViewValue('all'),
    },
    {
      content: 'Preventivi',
      id: 'Preventivi',
      onAction: () => setViewValue('quote'),
    },
    {
      content: 'Richieste di preventivo',
      id: 'Richieste di preventivo',
      onAction: () => setViewValue('to_be_quoted'),
    },
    {
      content: 'Preventivi da completare',
      id: 'Preventivi da completare',
      onAction: () => setViewValue('to_be_completed'),
    },
    {
      content: 'Preventivi bloccati',
      id: 'Preventivi bloccati',
      onAction: () => setViewValue('blocked'),
    },
    {
      content: 'Preventivi rifiutati',
      id: 'Preventivi rifiutati',
      onAction: () => {
        setViewValue('quote_rejected');
        console.log('ok');
      },
    },
  ];

  const [selected, setSelected] = useState(0);

  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => void 0;

  /**
   * Filters
   */
  function disambiguateLabel(key: string, value: any) {
    switch (key) {
      case 'product':
        return value?.map((val: string) => `Prodotto ${val}`).join(', ');
      case 'metadata':
        return value?.map((val: string) => `Campo ${val}`).join(', ');
      default:
        return value;
    }
  }

  function isEmpty(value: string | string[] | [number, number] | undefined): boolean {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const filters: FilterInterface[] = [
    {
      key: 'product',
      label: 'Prodotto',
      filter: (
        <ChoiceList
          title="Prodotto"
          titleHidden
          choices={productChoices}
          selected={productsSelected || []}
          onChange={handleProductsSelectedChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'metadata',
      label: 'Campi',
      filter: (
        <ChoiceList
          title="Campi"
          titleHidden
          choices={metadataChoices}
          selected={metadataSelected || []}
          onChange={handleMetadataSelectedChange}
          allowMultiple
        />
      ),
      shortcut: true,
      disabled: isEmpty(productsSelected),
    },
    {
      key: 'fieldValue',
      label: 'Contiene valore',
      filter: <TextField label="Cerca" value={fieldValue} onChange={handleFieldValueChange} autoComplete="off" labelHidden />,
      disabled: isEmpty(metadataSelected),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(productsSelected)) {
    const key = 'product';
    appliedFilters.push({
      key,
      // label: disambiguateLabel(key, productsSelected),
      label: disambiguateLabel(
        key,
        productChoices.filter((choice) => productsSelected?.includes(choice.value)).map((choice) => choice.label),
      ),
      onRemove: handleProductsSelectedRemove,
    });
  }
  if (!isEmpty(metadataSelected)) {
    const key = 'metadata';
    appliedFilters.push({
      key,
      label: disambiguateLabel(
        key,
        metadataChoices.filter((choice) => metadataSelected?.includes(choice.value)).map((choice) => choice.label),
      ),
      onRemove: handleMetadataSelectedRemove,
    });
  }
  if (!isEmpty(fieldValue)) {
    const key = 'fieldValue';
    appliedFilters.push({
      key,
      label: `Contiene valore: ${fieldValue}`,
      onRemove: handleFieldValueRemove,
    });
  }

  /**
   * Filtering
   */
  useEffect(() => {
    const filterItems = () => {
      if (!isEmpty(productsSelected)) {
        const filteredItems = items.filter((order: Order) => {
          return order.line_items.some((lineItem) => productsSelected?.includes(lineItem._id));
        });
        setFrontItems(filteredItems);

        // Set metadata choices related to the selected products
        const tmpMetadataChoices: { label: string; value: string }[] = [];
        for (const item of filteredItems as Order[]) {
          if (item.metadata !== undefined && item.metadata[0] && item.metadata[0].fields.length > 0) {
            for (const metadata of item.metadata[0].fields as QuotationMetadata[]) {
              if (!tmpMetadataChoices.some((choice) => choice.label === metadata.label)) {
                tmpMetadataChoices.push({ label: metadata.label, value: metadata.label });
              }
            }
          }
        }
        setMetadataChoices(tmpMetadataChoices);

        if (!isEmpty(metadataSelected)) {
          const filteredItems = items.filter((order: Order) => {
            if (order.metadata === undefined || order.metadata.length === 0 || order.metadata[0].fields === undefined) return false;
            return order.metadata[0].fields.some((field) => {
              if (field.value === undefined) return false;
              return metadataSelected?.includes(field.label) && (field.value as string).toLowerCase().includes(fieldValue ? fieldValue : '');
            });
          });
          setFrontItems(filteredItems);
        }
      }

      if (queryValue !== '') {
        // Allow the search to be case insensitive and performed on name, lastname and email
        const filteredItems = items.filter((order: Order) => {
          return (
            order.user?.name?.toLowerCase().includes(queryValue.toLowerCase()) ||
            order.user?.lastname?.toLowerCase().includes(queryValue.toLowerCase()) ||
            order.user?.email?.toLowerCase().includes(queryValue.toLowerCase())
          );
        });
        setFrontItems(filteredItems);
      }

      // Empty fieldValue but metadataSelected is not empty
      if (isEmpty(fieldValue) && !isEmpty(metadataSelected)) {
        const filteredItems = items.filter((order: Order) => {
          if (order.metadata === undefined || order.metadata.length === 0 || order.metadata[0].fields === undefined) return false;
          return order.metadata[0].fields.some((field) => metadataSelected?.includes(field.label));
        });
        setFrontItems(filteredItems);
      }

      // Empty metadataSelected but productsSelected is not empty
      if (isEmpty(metadataSelected) && !isEmpty(productsSelected)) {
        const filteredItems = items.filter((order: Order) => {
          return order.line_items.some((lineItem) => productsSelected?.includes(lineItem._id));
        });
        setFrontItems(filteredItems);
      }

      if (isEmpty(productsSelected) && queryValue === '') {
        setFrontItems(items);
        setMetadataChoices(deselectedMetadataChoices);
      }
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, productsSelected, metadataSelected, deselectedMetadataChoices, fieldValue]);

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_CREATED_ASC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_DESC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      setFrontItems(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  /**
   * Index table handlers & markup
   */
  const resourceIDResolver = (orders: { _id: any }) => {
    return orders._id;
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(frontItems, {
    resourceIDResolver,
  });

  // Archive selected orders
  const handleArchive = useCallback(async () => {
    try {
      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/orders`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          products: selectedResources,
        }),
      });
      const response = await data.json();

      if (response.status === 'success' && response.data) {
        setCounterError(true);
        setUpdate(!update);
      } else if (response.status === 'success') {
        setActive(true);
        setUpdate(!update);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      console.log(error);
      setSaveError(true);
    }
  }, [selectedResources, setActive, setCounterError, setSaveError, update]);

  const promotedBulkActions = [
    {
      content: 'Archivia ordini',
      onAction: handleArchive,
    },
  ];

  // Row markup
  const rowMarkup = frontItems.map((item: Order, index) => {
    const { _id: id, status, user, total_price, line_items, date_created, date_start } = item;

    const productName =
      line_items.length > 1
        ? `${line_items[0].product.name} ${line_items[0].name} + ${line_items.length - 1} altri`
        : `${line_items[0].product.name} - ${line_items[0].name}`;
    const statusMarkup = renderOrderStatusBadge(status);

    let dateCreated = new Date(date_created).toLocaleDateString('it-IT', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    if (status === 'paid' && date_start) {
      dateCreated = new Date(date_start).toLocaleDateString('it-IT', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    }

    return (
      // @ts-ignore
      <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
        <IndexTable.Cell>
          <Link url={`/quotes/${id}`} removeUnderline monochrome dataPrimaryLink>
            {user?.lastname ? (
              <>
                <Text as="span" fontWeight="semibold">
                  {user.lastname}
                </Text>{' '}
                {user.name}
              </>
            ) : (
              <Text as="span" fontWeight="semibold">
                {user?.name}
              </Text>
            )}
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>{productName}</IndexTable.Cell>
        <IndexTable.Cell>{dateCreated}</IndexTable.Cell>
        <IndexTable.Cell>{statusMarkup}</IndexTable.Cell>
        <IndexTable.Cell>{total_price.toFixed(2).replace('.', ',')} €</IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  const emptyList = (
    <EmptyState
      heading="Gestisci i tuoi preventivi"
      image="https://cdn.shopify.com/shopifycloud/web/assets/v1/0ecc04f85cc74cb401e71254d8a8b83dc03bdade26037cdda6a034831d940dcd.svg"
      action={{
        content: 'Crea un preventivo',
        onAction: () => {
          navigate('/orders/new');
        },
      }}
    >
      <p>Qua è dove puoi gestire i tuoi preventivi.</p>
    </EmptyState>
  );

  /**
   * Markup with items
   */
  useEffect(() => {
    if (items.length > 0) setIsLoading(false);
  }, [items]);

  const customerListMarkup = (
    <LegacyCard>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <IndexFilters
            queryValue={queryValue}
            filters={filters}
            // @ts-ignore
            appliedFilters={appliedFilters}
            queryPlaceholder={'Filtra preventivi per nome, cognome e email'}
            onQueryChange={setQueryValue}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
            cancelAction={{
              onAction: onHandleCancel,
              disabled: false,
              loading: false,
            }}
            canCreateNewView={false}
            mode={mode}
            setMode={setMode}
            selected={selected}
            tabs={tabs}
            onSelect={(newSelected) => {
              setSelected(newSelected);
              const selectedTab = tabs[newSelected];
              if (selectedTab && selectedTab.onAction) {
                selectedTab.onAction();
              }
            }}
          />
        </div>
        <div style={{ paddingLeft: '0.4rem' }}>
          <Select
            labelInline
            label="Ordina per"
            options={[
              { label: 'Data aggiunta preventivo (dal più recente)', value: 'DATE_CREATED_DESC' },
              { label: 'Data aggiunta preventivo (dal meno recente)', value: 'DATE_CREATED_ASC' },
            ]}
            value={sortValue}
            onChange={(selected) => {
              setSortValue(selected);
            }}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        emptyState={emptyList}
        loading={isLoading}
        itemCount={frontItems.length}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        hasMoreItems
        promotedBulkActions={promotedBulkActions}
        onSelectionChange={handleSelectionChange}
        headings={[{ title: 'Cliente' }, { title: 'Prodotto' }, { title: 'Data creazione' }, { title: 'Stato' }, { title: 'Totale' }]}
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );

  return customerListMarkup;
}
